<template>
  <div>
    <ul class="space-y-8">
      <li v-for="(project, index) in projects" :key="index" class="space-y-4">
        <h4 class="text-green-300">{{ project.projectName }}</h4>
        <p v-html="project.projectDescription" />
        <a :href="project.projectLink" target="_blank" class="text-red-300">{{
          project.projectLink
        }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import projects from "../../constants/projects";
export default {
  data() {
    return {
      projects,
    };
  },
};
</script>
