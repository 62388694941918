export default [
  {
    command: "help",
    value: "List of commands which can be executed & relative description",
  },
  {
    command: "about",
    value: "Some information about myself",
  },
  {
    command: "experience",
    value: "Displays overall experience",
  },
  {
    command: "contact",
    value: "Some Urls/Emails where to contact me",
  },
  {
    command: "start github",
    value: "New tab with my github repos",
  },
  {
    command: "cls",
    value: "Clear screen",
  },
];
