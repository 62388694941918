<template>
  <a :href="link" :download="download" class="text-red-300">{{ text }}</a>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "DanInput",
  props: {
    text: {
      type: String,
    },
    link: {
      type: String,
    },
    download: {
      type: String,
    },
  },
});
</script>
