<template>
  <ul class="space-y-3">
    <li v-for="(item, index) in help" :key="index">
      <div class="grid grid-cols-2 gap-4">
        <h4
          v-if="item.command"
          @click="commandClickHandler(item.command)"
          class="cursor-pointer text-red-300"
        >
          {{ item.command }}
        </h4>
        <p :class="!item.command ? 'col-span-2' : ''">{{ item.value }}</p>
      </div>
    </li>
  </ul>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import help from "../../constants/help";

export default defineComponent({
  data() {
    return {
      help,
    };
  },
  methods: {
    commandClickHandler(command) {
      this.$emit("command-auto-type", command);
    },
  },
});
</script>
