export const getScreenStream = async () => {
  const displayMediaOptions = {
    video: {
      cursor: "always",
    },
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100,
    },
  };

  let captureStream = null;

  try {
    captureStream = await navigator.mediaDevices.getDisplayMedia(
      displayMediaOptions
    );
    console.log(captureStream);
  } catch (err) {
    console.error(`Error: ${err}`);
  }
  return captureStream;
};

export const startMediaRecording = (stream) => {
  const mime = MediaRecorder.isTypeSupported("video/webm; codecs=vp9")
    ? "video/webm; codecs=vp9"
    : "video/webm";
  let mediaRecorder = new MediaRecorder(stream, {
    mimeType: mime,
  });

  //we have to start the recorder manually
  mediaRecorder.start();

  return mediaRecorder;
};
