<template>
  <div>
    <table class="hidden md:block -ml-10px" cellpadding="10">
      <thead>
        <tr>
          <th
            v-for="(value, index) in experienceHeaders"
            :key="index"
            class="text-left"
          >
            {{ value }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, index) in experience" :key="index">
          <td>
            <h4>{{ value.year }}</h4>
          </td>
          <td>
            <p>{{ value.title }}</p>
          </td>
          <td>
            <p>{{ value.company }}</p>
          </td>
          <td>
            <p>{{ value.techStack.join(", ") }}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <ul class="md:hidden space-y-3">
      <li v-for="(value, index) in experience" :key="index">
        <div class="grid grid-cols-1 gap-2 mt-6">
          <h4>{{ value.year }}</h4>
          <p>{{ value.title }}</p>
          <p>{{ value.company }}</p>
          <p>{{ value.techStack.join(", ") }}</p>
        </div>
      </li>
    </ul>
    <div class="mt-8 flex flex-col md:flex-row">
      <p class="font-semibold">Other experiences/knowledge -</p>
      <p>{{ otherKnowledge.join(", ") }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import {
  experienceHeaders,
  experience,
  otherKnowledge,
} from "../../constants/experience";

export default defineComponent({
  data() {
    return {
      experienceHeaders,
      experience,
      otherKnowledge,
    };
  },
});
</script>

<style scoped>
.-ml-10px {
  margin-left: -10px;
}
</style>
