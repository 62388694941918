<template>
  <div class="space-y-16">
    <div>
      <h2 class="text-2xl text-yellow-300">Experience</h2>
      <Experience />
    </div>
    <div>
      <h2 class="text-2xl text-yellow-300">Some Github Projects</h2>
      <GithubProjects />
    </div>
  </div>
</template>

<script>
import Experience from "./Experience.vue";
import GithubProjects from "./GithubProjects.vue";

export default {
  components: { Experience, GithubProjects },
};
</script>
