export default [
  {
    projectName: "har2request",
    projectDescription:
      "This package was created to be able to quickly extract all calls made in a har file, so as to speed up some automation work I needed elsewhere. It was a fun small project which I find useful sometimes to use. This package analyzes a har file and create JS code with fetch calls to redo the requests (including body/headers/etc..) Then you can easily copy any calls which you might need and replay accordingly.",
    projectLink: "https://github.com/danielbriffa/har2request",
  },
  {
    projectName: "Wordpress-Migrator",
    projectDescription:
      "The original scope of this project was to be familiar with wordpress, but ended up growing a bit more to serve some projects. This wordpress migrator, although is quite raw, is quite powerful as it contains a lot of customization which can be done on the fly while importing. Example add classes, change elements, import any images and change reference",
    projectLink: "https://github.com/danielbriffa/Wordpress-Migrator",
  },
];
