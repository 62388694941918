<template>
  <div class="p-2">
    <a class="text-red-300" href="/">DanielBriffa.com</a>
    <br />
    Type help to get all supported commands,and be able to interact correctly
    <br />
    Use `Tab` for autocomplete & anything in red is clickable
    <br />
    <span class="text-xs hidden"
      >↑ ↑ ↓ ↓ ← → ← → B A and unlock some easter eggs</span
    >
    <br />
    <span
      @click="updateCurrentInputText('CutTheBullShowCV')"
      class="cursor-pointer text-red-300"
      >Or click here to cut the crap and show CV</span
    >

    <div class="flex flex-col space-y-3 mt-8">
      <section
        v-for="item in componentList"
        :key="item.key"
        class="flex flex-col lg:flex-row lg:space-x-12"
      >
        <div class="text-green-300">guest@danielbriffa.com:~$</div>
        <component
          class="flex-grow"
          :is="item.component"
          v-bind="item.bind"
          @value-updated="enterHandler"
          @tab-key="autocompleteCommand"
          @command-auto-type="updateCurrentInputText"
        ></component>
      </section>
    </div>
  </div>
</template>

<script>
import { DanInput, DanLink } from '../components/atoms';
import {
  Help,
  Contact,
  Experience,
  About,
  CommandError,
  CutTheBullShowCv
} from '../components/templates';
import { components, functions } from '../constants/components';
import help from '../constants/help';

export default {
  name: 'Home',
  components: {
    DanInput,
    DanLink,
    Help,
    Contact,
    Experience,
    About,
    CutTheBullShowCv,
    CommandError
  },
  data() {
    return {
      componentList: [],
      currentInputKey: undefined
    };
  },
  created() {
    this.currentInputKey = this.createRandomKey();
    this.componentList = [
      { component: 'DanInput', bind: {}, key: this.currentInputKey }
    ];
  },
  computed: {
    helpList() {
      return help.map((item) => item.command);
    }
  },
  methods: {
    autocompleteCommand(text) {
      let autocompleted = this.helpList.filter((item) => item.startsWith(text));
      if (autocompleted.length > 0) {
        this.updateCurrentInputText(autocompleted[0]);
      }
    },
    updateCurrentInputText(text) {
      const currentIndex = this.componentList.findIndex(
        (item) => item.key === this.currentInputKey
      );

      this.$set(this.componentList[currentIndex].bind, 'value', text);
    },
    createRandomKey() {
      return (Math.random() + 1).toString(36).substring(2);
    },
    getComponent(text) {
      return components[text.toLowerCase()];
    },
    enterHandler(text, bind = {}) {
      const component = this.getComponent(text);
      if (component) {
        this.componentList.push({ component, bind });
      } else if (functions[text]) {
        functions[text](this);
      } else {
        this.componentList.push({
          component: 'CommandError',
          bind: {
            text
          }
        });
      }

      this.$nextTick(() => {
        this.currentInputKey = this.createRandomKey();
        this.componentList.push({
          component: 'DanInput',
          key: this.currentInputKey,
          bind: {}
        });
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
