<template>
  <input
    ref="dan-input"
    :autofocus="readonly ? false : true"
    :onblur="readonly ? '' : 'this.focus()'"
    type="text"
    @keydown="keyDownHandler"
    @keyup="keyUpHandler"
    v-model="dataVal"
    :readonly="readonly"
  />
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "DanInput",
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      dataVal: this.value,
      readonly: false,
    };
  },
  watch: {
    value(val) {
      this.dataVal = val;
    },
  },
  methods: {
    keyDownHandler(e) {
      if (e.keyCode === 9) {
        this.$emit("tab-key", this.dataVal);
        e.preventDefault();
      }

      return false;
    },
    keyUpHandler(e) {
      if (e.keyCode === 13) {
        this.$emit("value-updated", this.dataVal);
        this.readonly = true;
      }
    },
  },
  mounted() {
    //on next tick, focus on element.
    this.$nextTick(() => {
      this.$refs["dan-input"].focus();
    });
  },
});
</script>

<style scoped>
input {
  background: transparent;
  border: 0px;
  outline: none;
}
</style>
