<template>
  <div v-if="suggestedCommand">
    '{{ text }}' is not recognised as an internal or external command. Unable to
    execute! <br /><br />
    The most similar command is <br />
    <span
      class="cursor-pointer text-red-300"
      @click="commandClickHandler(suggestedCommand)"
      >{{ suggestedCommand }}</span
    >
  </div>
  <div v-else>
    '{{ text }}' is not recognised as an internal or external command. Unable to
    execute!
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import help from "../../constants/help";
import stringSimilarity from "string-similarity";

export default defineComponent({
  props: {
    text: {
      type: String,
    },
  },
  data() {
    return {
      suggestedCommand: undefined,
      ratingValue: 0.4,
    };
  },
  computed: {
    helpList() {
      return help.map((item) => item.command);
    },
  },
  mounted() {
    var matches = stringSimilarity.findBestMatch(this.text, this.helpList);
    if (matches.bestMatch.rating > this.ratingValue) {
      this.suggestedCommand = matches.bestMatch.target;
    }
  },
  methods: {
    commandClickHandler(command) {
      this.$emit("command-auto-type", command);
    },
  },
});
</script>
