import { getScreenStream, startMediaRecording } from '../helpers/helpers.js';

export const components = {
  help: 'help',
  experience: 'experience',
  contact: 'contact',
  about: 'about',
  danlink: 'DanLink',
  cutthebullshowcv: 'CutTheBullShowCv'
};

export const functions = {
  'start github': () => {
    window.open('https://github.com/danielbriffa', '_blank');
  },
  cls: (vm) => {
    vm.componentList = [];
  },
  sos: () => {
    navigator.vibrate([
      100, 30, 100, 30, 100, 30, 200, 30, 200, 30, 200, 30, 100, 30, 100, 30,
      100
    ]);
  },
  'capture-screen': async (vm) => {
    let stream = await getScreenStream();
    let mediaRecorder = startMediaRecording(stream, vm);

    let chunks = [];
    mediaRecorder.addEventListener('dataavailable', function (e) {
      chunks.push(e.data);
    });

    mediaRecorder.addEventListener('stop', function () {
      console.log('stopp');
      let blob = new Blob(chunks, {
        type: chunks[0].type
      });

      let blobSize = (blob.size / 1024).toFixed(0);

      let url = URL.createObjectURL(blob);
      console.log(url);

      vm.enterHandler('DanLink', {
        text: `ScreenCapture - ${blobSize}Kb`,
        link: url,
        download: 'video.webm'
      });
    });

    // when user clicks stop sharing, it does not always trigger the stop event
    // this is a bit of a hack, honestly, not entirely confident, but solves the problem
    stream.getTracks().forEach((track) =>
      track.addEventListener('ended', () => {
        if (mediaRecorder) {
          mediaRecorder.stop();
        }
      })
    );
  }
};
