<template>
  <div>
    Just a random guy who's eager to learn, experiment & improve. Loves
    development, indie + simulation games & anything which flies 😊. That's all,
    if you expected more, sorry to disappoint.
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({});
</script>
