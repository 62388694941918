export const experienceHeaders = ["Period", "Title", "Company", "Tech Stack"];
export const experience = [
  {
    year: "2021-",
    title: "Software Developer",
    company: "MeDirect Malta",
    experience: "",
    techStack: ["Vue.js", "JS", "TailwindCss"],
  },
  {
    year: "2019-2021",
    title: "Software Developer",
    company: "BRND WGN",
    experience: "",
    techStack: ["Wordpress", "PHP", "Laravel", "Vue", "Bootstrap", "MySQL"],
  },
  {
    year: "2017-2019",
    title: "Head of Software Development",
    company: "Bit8",
    experience: "",
    techStack: ["MySQL", "Yii/PHP", "JS", "C#"],
  },
  {
    year: "2016-2017",
    title: "Team Lead",
    company: "Bit8",
    experience: "",
    techStack: ["MySQL", "Yii/PHP", "JS", "C#"],
  },
  {
    year: "2013-2015",
    title: "Software Developer",
    company: "Bit8",
    experience: "",
    techStack: ["MySQL", "Yii/PHP", "JS/jQuery"],
  },
  {
    year: "2012-2013",
    title: "Software Developer",
    company: "EBN Software",
    experience: "",
    techStack: ["MySQL / MsSQL", "PHP", "JS/jQuery", "C#"],
  },
];

export const otherKnowledge = [
  "NodeJs",
  "MongoDB",
  "Agile",
  "Scrum",
  "Leadership",
  "Mentoring",
];
