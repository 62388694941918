<template>
  <div>
    <table class="hidden md:block -ml-10px" cellpadding="10">
      <tbody>
        <tr v-for="(value, key) in contact" :key="key">
          <td>
            <h4>{{ key }}</h4>
          </td>
          <td>
            <div v-html="value" class="text-red-300"></div>
          </td>
        </tr>
      </tbody>
    </table>
    <ul class="md:hidden space-y-3">
      <li v-for="(value, key) in contact" :key="key">
        <div class="grid grid-cols-1 gap-2 mt-6">
          <h4>{{ key }}</h4>
          <div v-html="value" class="text-red-300"></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import contact from '../../constants/contact';

export default defineComponent({
  data() {
    return {
      contact
    };
  }
});
</script>

<style scoped>
.-ml-10px {
  margin-left: -10px;
}
</style>
